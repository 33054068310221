/**
 * @generated SignedSource<<b6226038aa29a07679e5f2c35f33a0d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type OrganizationRole = "ADMIN" | "MEMBER" | "OWNER";
import { FragmentRefs } from "relay-runtime";
export type MemberOrganization_organizationCardQuery$data = {
  readonly id: string;
  readonly image: string | null | undefined;
  readonly name: string;
  readonly organizationUser: {
    readonly role: OrganizationRole;
  } | null | undefined;
  readonly organizationUsers: {
    readonly totalCount: number;
  };
  readonly studies: {
    readonly totalCount: number;
  };
  readonly " $fragmentType": "MemberOrganization_organizationCardQuery";
};
export type MemberOrganization_organizationCardQuery$key = {
  readonly " $data"?: MemberOrganization_organizationCardQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberOrganization_organizationCardQuery">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MemberOrganization_organizationCardQuery",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationUser",
      "kind": "LinkedField",
      "name": "organizationUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationStudiesConnection",
      "kind": "LinkedField",
      "name": "studies",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationOrganizationUsersConnection",
      "kind": "LinkedField",
      "name": "organizationUsers",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "fd4b2cd6dad5a9dc9781751ae30f5efa";

export default node;
